import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaOne from "@components/introarea/layout-2";
import IntroAreaTwo from "@components/introarea/layout-1";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import ImageSection from "@components/ImageBox";
import CaseStudyFacts from "@containers/case-study/facts";
import IntroAreaReverseTwo from "@components/introareareverse/layout-3";

const CaseStudyTravelocity = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Case study - Travelocity"
        description="Andovar offered more competitive pricing and a larger mix of translation options, including Machine Translation and post-editing, than other vendors."
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["travelocity-header-section"]} />
        <ImageSection data={content["case-study-travelocity-image-body"]} />
        <CaseStudyFacts data={content["travel-fast-facts"]} />
        <IntroAreaReverseTwo
          layout={3}
          data={content["case-study-testimonial"]}
        />
        <IntroAreaOne data={content["case-study-travel"]} />
        <IntroAreaReverse layout={2} data={content["travelocity-intro"]} />
        <IntroAreaTwo data={content["travelocity-solutions"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyTravelocityPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "case-studies" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudyTravelocity.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudyTravelocity;
